<template>
  <div>
    <b-card>
      <own-table
        ref="faqTable"
        name-table="faqs"
        :table-columns="columns"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        :export-excel="false"
        sort-by="created_at"
        sort-direction="desc"
        :disable-create-btn="true"
        @searchFieldOnChange="searchOnChange"
      >
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(customer)="data">
          <span>{{ data.data.item.customer.nama_customer }}</span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(is_replied)="data">
          <b-badge :variant="statusVariant(data.data.item.is_replied)">
            <span>{{
              data.data.item.is_replied === 1 ? 'Sudah' : 'Belum'
            }}</span>
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle text-body"
              @click="
                $router.push({
                  name: 'faq-show',
                  params: { id: data.data.item.id },
                })
              "
            />
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import service from '@/components/Table/service'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const faqTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Kd Produk',
        key: 'kd_produk',
      },
      {
        label: 'Customer',
        key: 'customer',
      },
      {
        label: 'Question',
        key: 'content',
      },
      {
        label: 'Status Reply',
        key: 'is_replied',
        sortable: true,
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const returnReceiveData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      console.log(typeForm.value)
      if (typeForm.value === 'add') {
        service
          .store({ url: 'return-receive', data: formData })
          .then(res => {
            faqTable.value.refetchData()
            isShowFormAdd.value = false
          })
          .catch(err => {
            console.log(err)
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, err),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error Add Data',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        service
          .update({ url: 'return-receive', data: formData })
          .then(res => {
            faqTable.value.refetchData()
            isShowFormAdd.value = false
          })
          .catch(err => {
            console.log(err)
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, err),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error Edit Data',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      returnReceiveData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const setStatus = data => {
      const form = data
      form.is_active = data.is_active === 1 ? 0 : 1
      faqTable.value.onEdit()
      faqTable.value.submit(form, () => {})
    }

    const edit = data => {
      typeForm.value = 'edit'
      faqTable.value.onEdit()
      returnReceiveData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      faqTable.value.onDelete(id)
    }
    // const formatDateTime = date => formatDate(date)

    return {
      columns,
      queryFilter,
      faqTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      setStatus,
      edit,
      returnReceiveData,
      typeForm,
      onDelete,
    }
  },
}
</script>
